import {Col, Container, Form, Row} from "react-bootstrap";
import React from "react";
import {Formik} from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import {VenueInterface} from "../../../@interfaces/VenueInterface";
import {useTranslation} from "react-i18next";
import {OptionType} from "../../../@interfaces/OptionType";

interface EventsFiltersProps {
    setFiltersValue: (value: any) => void;
    venues: VenueInterface[]
}

function EventsFilters({setFiltersValue, venues}: EventsFiltersProps) {
    const {t, i18n} = useTranslation();
    const formInitialValues = {
        localStartDate: null,
        localEndDate: null,
        tags: [] as OptionType[],
        owners: [] as OptionType[],
        venues: [] as OptionType[],
    };

    const searchFormSchema = yup.object().shape({
        localStartDate: yup.date().nullable()
            .test('localStartDate-required-if-localEndDate', i18n.t('eventFilter.localStartDateRequired'), function (value) {
                const {localEndDate} = this.parent;
                if (localEndDate && !value) {
                    return false;
                }
                return true;
            })
            .test('localStartDate-before-localEndDate', i18n.t('eventFilter.localStartDateError'), function (value) {
                const {localEndDate} = this.parent;
                if (value && localEndDate && value > localEndDate) {
                    return false;
                }
                return true;
            }),
        localEndDate: yup.date().nullable()
            .test('localEndDate-required-if-localStartDate', i18n.t('eventFilter.localEndDateRequired'), function (value) {
                const {localStartDate} = this.parent;
                if (localStartDate && !value) {
                    return false;
                }
                return true;
            })
            .test('localEndDate-after-localStartDate', i18n.t('eventFilter.localEndDateError'), function (value) {
                const {localStartDate} = this.parent;
                if (value && localStartDate && value < localStartDate) {
                    return false;
                }
                return true;
            }),
        tags: yup.array().optional(),
        owners: yup.array().optional(),
        venues: yup.array().optional()
    });

    const tagsOptions: OptionType[] = [
        {value: 'tag1', label: 'Tag 1'},
        {value: 'tag2', label: 'Tag 2'},
        {value: 'tag3', label: 'Tag 3'},
    ];

    const ownersOptions: OptionType[] = [
        {value: 'owner1', label: 'Owner 1'},
        {value: 'owner2', label: 'Owner 2'},
        {value: 'owner3', label: 'Owner 3'},
    ];

    const venuesOptions: OptionType[] = venues.map((venue: VenueInterface) => {
        return {value: venue.id, label: venue.name}
    })


    const handleFilterFormSubmit = (values: any) => {
        setFiltersValue(values);
    }

    return (
        <div className="accordion" id="accordionEventFilter">
            <div className="accordion-item border-0">
                <h2 className="accordion-header mt-0">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {t('common.filters')}
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                     data-bs-parent="#accordionEventFilter">
                    <div className="accordion-body">
                        <Formik
                            validationSchema={searchFormSchema}
                            onSubmit={handleFilterFormSubmit}
                            initialValues={formInitialValues}
                        >
                            {({handleSubmit, setFieldValue, values, touched, errors, resetForm}) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Container>
                                        <Row className="gy-5">
                                            <Col lg={4} md={4} sm={12}>
                                                <Form.Label>{t('common.localStartDate')}</Form.Label>
                                                <Form.Control
                                                    type="datetime-local"
                                                    aria-label="localStartDate"
                                                    name="localStartDate"
                                                    value={values.localStartDate || ''}
                                                    onChange={(e) => setFieldValue('localStartDate', e.target.value)}
                                                    isInvalid={touched.localStartDate && !!errors.localStartDate}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.localStartDate}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <Form.Label>{t('common.localEndDate')}</Form.Label>
                                                <Form.Control
                                                    type="datetime-local"
                                                    aria-label="localEndDate"
                                                    name="localEndDate"
                                                    value={values.localEndDate || ''}
                                                    onChange={(e) => setFieldValue('localEndDate', e.target.value)}
                                                    isInvalid={touched.localEndDate && !!errors.localEndDate}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.localEndDate}
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col lg={4} md={4} sm={12}>
                                                <Form.Label>{t('common.tags')}</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={(selected) => setFieldValue('tags', selected)}
                                                    isMulti
                                                    options={tagsOptions}
                                                    value={values.tags}
                                                />
                                                {touched.tags && errors.tags && (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.tags as string}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="gy-3">
                                            <Col lg={6} md={6} sm={12}>
                                                <Form.Label>{t('common.owner')}</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={(selected) => setFieldValue('owners', selected)}
                                                    isMulti
                                                    options={ownersOptions}
                                                    value={values.owners}
                                                />
                                                {touched.owners && errors.owners && (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.owners as string}
                                                    </div>
                                                )}
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>
                                                <Form.Label>{t('common.venue')}</Form.Label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    onChange={(selected) => setFieldValue('venues', selected)}
                                                    isMulti
                                                    options={venuesOptions}
                                                    value={values.venues}
                                                />
                                                {touched.venues && errors.venues && (
                                                    <div className="invalid-feedback d-block">
                                                        {errors.venues as string}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12}
                                                 className="d-flex flex-row justify-content-center py-3">
                                                <button className="btn btn-primary btn-sm mt-2" type="submit"><i
                                                    className="bi bi-funnel"></i> {t('common.applyFilter')}
                                                </button>
                                                <button className="btn btn-info btn-sm mt-2 "
                                                        type="button" onClick={() => {
                                                        resetForm();
                                                        setFiltersValue({})
                                                }}>
                                                    <i className="bi bi-trash"></i>{t('common.resetFilter')}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventsFilters;
