import axiosClient from "./AxiosInstance";


import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";
import {CompanyInterface} from "../@interfaces/CompanyInterface";


// Define the expected response structure
interface CompaniesResponse {
    data: CompanyInterface[];
    headers: any;
}



export default class CompaniesService {
    static async retrieveCompanies({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    }): Promise<CompaniesResponse> {
        try {
            const formattedFilters = CompaniesService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/companies?${!isEmpty(formattedFilters) ? formattedFilters : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static async retrieveCompany(uuid: number) {
        try {
            const response = await axiosClient.get(`/companies/${uuid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }



    static formatFiltersToQueryString(filters:any) {
        if(!isEmpty(filters)){
            const filter = {
                venues_ids: filters.venues.map((val:any) => val.value)
            }

            return new URLSearchParams(Object.entries(filter)).toString();
        }

        return filters;
    }
}