import {Pagination, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";

interface PaginationProps {
    totalItems: number;
    itemsPerPage: number;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
    totalPages: number;
}

function PaginationComponent({totalItems, itemsPerPage, currentPage, onPageChange, totalPages}: PaginationProps) {
    const {t, i18n} = useTranslation();
    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
        }
    };

    const renderPaginationItems = () => {
        const items = [];
        const maxPageNumbersToShow = 8; // Adjust this value to show more or fewer page numbers
        let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

        if (endPage - startPage < maxPageNumbersToShow - 1) {
            startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
        }

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis"/>);
            }
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis"/>);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    // Calculate the start and end item numbers for the current page
    const startItemNumber = (currentPage - 1) * itemsPerPage + 1;
    const endItemNumber = Math.min(currentPage * itemsPerPage, totalItems);

    return (
        <div className="d-flex justify-content-between">

            <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1}/>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}/>
                {renderPaginationItems()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)}
                                 disabled={currentPage === totalPages}/>
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}/>
            </Pagination>
            <Row className="mt-2">
                <Col>
                    <span>
                        {t('common.pagination', {
                            startItemNumber: startItemNumber,
                            endItemNumber: endItemNumber,
                            totalItems: totalItems
                        })}
                    </span>
                </Col>
            </Row>
        </div>
    );
}

export default PaginationComponent;
