import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";

import React from "react";

interface VenuesFiltersProps {
    setFiltersValue: (value: any) => void;

}

function VenuesFilters({setFiltersValue}: VenuesFiltersProps) {
    const {t} = useTranslation();
    const formInitialValues = {
        address: null,
    };

    const searchFormSchema = yup.object().shape({
        address: yup.string().optional(),

    });

    const handleFilterFormSubmit = (values: any) => {
        setFiltersValue(values);
    }


    return (
        <div className="accordion" id="accordionEventFilter">
            <div className="accordion-item border-0">
                <h2 className="accordion-header mt-0">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {t('common.filters')}
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                     data-bs-parent="#accordionEventFilter">
                    <div className="accordion-body">
                        <Formik
                            validationSchema={searchFormSchema}
                            onSubmit={handleFilterFormSubmit}
                            initialValues={formInitialValues}
                        >
                            {({handleSubmit, setFieldValue, values, touched, errors, resetForm}) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Container>
                                        <Row className="gy-2">
                                            <Col lg={12} md={12} sm={12}>
                                                <Form.Label>{t('common.name')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    aria-label="name"
                                                    name="name"
                                                    value={values.address || ''}
                                                    onChange={(e) => setFieldValue('name', e.target.value)}
                                                    isInvalid={touched.address && !!errors.address}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col lg={12} md={12} sm={12}
                                                 className="d-flex flex-row justify-content-center py-3">
                                                <button className="btn btn-primary btn-sm mt-2" type="submit"><i
                                                    className="bi bi-funnel"></i> {t('common.applyFilter')}
                                                </button>
                                                <button className="btn btn-info btn-sm mt-2 "
                                                        type="button" onClick={() => {
                                                        resetForm();
                                                        setFiltersValue({})
                                                }}>
                                                    <i className="bi bi-trash"></i>{t('common.resetFilter')}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VenuesFilters;