const sideBarTranslation = {
    dashboard: 'Dashboard',
    messenger: 'Messagerie',
    events: 'Évènements',
    chanelManager: 'Chanel Manager',
    statistics: 'Statistiques',
    account: 'Comptes',
    companies:'Compagnies',
    venues: "Lieux",
    users: "Comptes/Utilisateurs"
}
export default sideBarTranslation