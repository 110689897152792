import {Button, Modal, Form, Container, Row, Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Formik} from "formik";
import React, {Fragment} from "react";
import {ACCOUNT_TYPE_LIST, COMPANY_LEGAL_STATUS_LIST} from "../../utils/constants";

interface CompanyInitializerFormModalProps {
    showModal: boolean;

    onHide(): void;
}

const accountTypeOptions = ACCOUNT_TYPE_LIST;
const renderAccountTypeOptions = (option: string) => {
    return <option value={option}>{option}</option>;
};
function CompanyInitializerFormModal({showModal, onHide}: CompanyInitializerFormModalProps) {
    const {t} = useTranslation();
    const formInitialValues = {
        email: null,
        name: null,
        businessName: null,
        personContact: null,
        accountType:null,
    };
    const validationSchema = yup.object().shape({
        name: yup.string().required(t('common.inputRequired')),
        email: yup.string().email(t('common.invalidEmail')).required(t('common.inputRequired')),
        businessName: yup.string().required(t('common.inputRequired')),
        personContact: yup.string().required(t('common.inputRequired')),
        accountType: yup.string().required(t('common.inputRequired'))
    });

    const handleFormSubmit = (values: any) => {
        console.log('Submitted values:', values);
        onHide();
    };

    return (
        <Modal
            show={showModal}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('common.initializeCompanyAccount')}
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({values, setFieldValue, handleSubmit, errors, touched}) => (
                    <>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body className="justify-content-center">
                                <Container>
                                    <Row>
                                        <Col lg={12} md={12} sm={12}>
                                            <Form.Group className="mb-3" controlId="companyInitializerFormNameInput">
                                                <Form.Label>{t('common.name')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    aria-label={t('common.name')}
                                                    name="name"
                                                    placeholder={t('common.pleaseEnterCompanyName')}
                                                    value={values.name || ''}
                                                    onChange={(e) => setFieldValue('name', e.target.value)}
                                                    isInvalid={touched.name && !!errors.name}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.name}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3"
                                                        controlId="companyInitializerFormBusinessNameInput">
                                                <Form.Label>{t('common.businessName')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    aria-label={t('common.businessName')}
                                                    name="businessName"
                                                    placeholder={t('common.pleaseEnterCompanyBusinessName')}
                                                    value={values.businessName || ''}
                                                    onChange={(e) => setFieldValue('businessName', e.target.value)}
                                                    isInvalid={touched.businessName && !!errors.businessName}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.businessName}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3"
                                                        controlId="companyInitializerFormPersonContactInput">
                                                <Form.Label>{t('common.personContact')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    aria-label={t('common.personContact')}
                                                    name="personContact"
                                                    placeholder={t('common.pleaseEnterCompanyPersonContact')}
                                                    value={values.personContact || ''}
                                                    onChange={(e) => setFieldValue('personContact', e.target.value)}
                                                    isInvalid={touched.personContact && !!errors.personContact}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.personContact}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="companyInitializerFormEmailInput">
                                                <Form.Label>{t('common.email')}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    aria-label={t('common.email')}
                                                    name="email"
                                                    placeholder={t('common.pleaseEnterCompanyEmail')}
                                                    value={values.email || ''}
                                                    onChange={(e) => setFieldValue('email', e.target.value)}
                                                    isInvalid={touched.email && !!errors.email}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.email}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="companyInitializerFormAccountTypeInput">
                                                <Form.Label>{t('common.accountType')}</Form.Label>
                                                <Form.Select aria-label={t('common.accountType')}
                                                             value={values.accountType || ''}
                                                             name="accountType"
                                                             onChange={(e) => {
                                                                 setFieldValue('accountType', e.target.value);
                                                             }}
                                                             isInvalid={touched?.accountType && !!errors?.accountType}>
                                                    <option>{t('common.pleaseSelect')}.</option>
                                                    {accountTypeOptions.map((opt) => (
                                                        <Fragment key={opt}>{renderAccountTypeOptions(opt)}</Fragment>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.accountType}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="rounded" variant="primary"
                                        type="submit">{t('common.save')}</Button>
                                <Button onClick={onHide} className="rounded"
                                        variant="danger">{t('common.close')}</Button>
                            </Modal.Footer>


                        </Form>

                    </>
                )}
            </Formik>
        </Modal>
    )
        ;
}


export default CompanyInitializerFormModal;