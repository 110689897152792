const commonTranslation = {
    pleaseSelect: "Please select...",
    callSign: "Call Sign",
    email: "Email",
    emailPlaceholder: "Email",
    website: "Website",
    vat: "VAT",
    name: "Name",
    namePlaceHolder: "Enter your name",
    firstNamePlaceHolder: "Enter your first name",
    firstname: "First Name",
    cityOfBirth: "City of Birth",
    countryOfBirth: "Country of Birth",
    birthday: "Date of Birth",
    share: "Share",
    tags: "Category",
    owner: "Owner",
    venue: "Venue",
    applyFilter: "Apply Filter",
    resetFilter: "Reset Filters",
    filters: "Search Filters",
    localStartDate: "Start Date",
    localEndDate: "End Date",
    loading: "Loading...",
    pagination: "Displaying {startItemNumber} to {endItemNumber} of {totalItems} items",
    save: "Save",
    close: "Close",
    password: "Password",
    noAccount: "Don't have an account yet?",
    createAccount: "Create an account",
    forgotPassword: "Forgot Password",
    canceled: "Cancel",
    pages: "pages",
    perPage: "per page",
    adminInfo: "Administrative Information",
    imatProof: "Registration or publication proofs",
    managementInformation: "Management Information",
    paymentMethod: "Payment Method",
    myEvents: "My Events",
    shareWithMe: "Shared with me",
    title: "Title",
    action: "Action",
    importedAt: "Imported on",
    dateShortWithSecond: "{{date, SHORT_WITH_SECONDS}}",
    status: "Status",
    inputRequired: "This field is required",
    invalidEmail: "Invalid email address",
    siret: "SIRET",
    pleaseEnterPhoneNumber: "Phone",
    attachmentRequired: "The documents: ({{value}}) are mandatory",
    localOffices: "Local Offices",
    addOffice: "Add office",
    removeOffice: "Remove office",
    invalidPhoneNumber: "Invalid Phone Number",
    manager: "Manager",
    addManager: "Add Manager",
    removeManager: "Remove manager",
    paymentMethods: "Payment Methods",
    invoices: "Invoices",
    invoiceNumber: "Invoice Number",
    unitPrice: "Unit Price",
    invoiceSentDate: "Invoice Sent Date",
    pleaseSelectPaymentMode: "Please select a payment method",
    transfert: "Transfer",
    card: "Card",
    pleaseEnterCardNumber: "Please enter the card number",
    cardNumber: "Card Number",
    expirationDate: "Expiration Date",
    CVV: "CVV",
    inputMinLength: "This field must contain at least {{value}} characters",
    inputMaxLength: "This field must contain a maximum of {{value}} characters",
    invalidExpirationDateFormat: "The expected format is MM/YY, MM=Month, YY=Year",
    download: "Download",
    iban: "IBAN",
    initializeCompanyAccount: "Account Initialization",
    businessName: "Business Name",
    personContact: "Contact Person",
    pleaseEnterCompanyName: "Enter the company or account name",
    pleaseEnterCompanyBusinessName: "Enter the business name",
    pleaseEnterCompanyPersonContact: "Enter the contact person's name",
    pleaseEnterCompanyEmail: "Enter the contact email",
    add:"Add",
    accountType: "Account Type",
     lastLogin:"Last Login",
    company:"Company",
    createdAt:"Created at",
    updatedAt:"Updated at",
    lastUpdatedAt:"Last Updated at",
    source:"Source",
    address:"Address",
    organisation:"Company",
    roles:"Roles",
    inputURLInvalid:"Invalid URL ex: https://ticketr;fr",
}
export default commonTranslation;
