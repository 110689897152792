import axios from 'axios';
import {logError} from "../utils/ErrorHandler";
import {handleResponse} from "../utils/ResponseWrapper";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
// @ts-ignore
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

//All request will wait 2 seconds before timeout
axiosClient.defaults.timeout = 10000;

//axiosClient.defaults.withCredentials = true;


axiosClient.interceptors.request.use(function (request) {
    //request.headers['Content-Type'] = 'Application/json';
    const token = localStorage.getItem('token'); // Replace with your token retrieval logic
    if (token && !request.url?.includes('http')) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
}, null, {synchronous: true});

axiosClient.interceptors.response.use(function (response) {
    //Dispatch any action on success

    return handleResponse(response)

}, function (error) {
    logError(error);

    if (error.response.status === 401) {
        //Add Logic to
        //1. Redirect to login page or
        //2. Request refresh token
    }
    return Promise.reject(error);
});

export default axiosClient;