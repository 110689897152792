import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

import PaginationComponent from "../../components/Pagination/PaginationComponent";
import {Button, Form} from "react-bootstrap";

import VenuesService from "../../services/VenuesService";
import VenuesFilters from "../../components/VenuesFilters/VenuesFilters";
import VenueListView from "../../views/VenueListView/VenueListView";
import {VenueInterface} from "../../@interfaces/VenueInterface";




interface VenuesPerPage {
    page: number;
    items: VenueInterface[];
}
export function VenuesListPage() {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [showInitializerFormModal, setShowInitializerFormModal] = useState(false);
    const [venues, setVenues] = useState<VenuesPerPage[]>([]);
    const [currentPageVenuesData, setCurrentPageVenuesDatas] = useState<VenueInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageVenues(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageVenues(pageNumber);
            } else {
                fetchVenues({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageVenues = (pageNumber: number): VenuesPerPage => {

        return venues.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageVenues = (pageNumber: number) => {
        try {
            const venuesPage = getCurrentPageVenues(pageNumber);
            setCurrentPageVenuesDatas(venuesPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setVenues([]);
            fetchVenues({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values:any)=>{
        try{
            fetchVenues({currentPage: 1, pageSize: itemsPerPage},values);
        } catch (e:any){
            console.error('error on line ', e);
        }
    }



    const fetchVenues = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await VenuesService.retrieveVenues({pagination: pagingData, filters: filter});
            let total_pages = Number.isNaN(Number(response.headers.total_pages)) ? 0 : Number(response.headers.total_pages);
            let page_size = Number.isNaN(Number(response.headers.page_size)) ? 0 : Number(response.headers.page_size);
            let total_count = Number.isNaN(Number(response.headers.total_count)) ? 0:Number(response.headers.total_count);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setVenues(prevData => [...prevData, {page:  Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page), items: response.data}]);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        fetchVenues();
    },[]);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <VenuesFilters  setFiltersValue={applyFilters}/>
            <div className="d-flex justify-content-between mt-4 mb-4">
                <PaginationComponent itemsPerPage={itemsPerPage} totalItems={totalCount} currentPage={currentPage}
                                     totalPages={totalPages} onPageChange={handlePageChange}/>
                <Form.Select aria-label="company filters select exemple" size="sm" className="custom-select"
                             onChange={handlePageSizeChange} value={itemsPerPage}>
                    <option value={0}>N / {t('common.pages')}</option>
                    <option value={50}>50 {t('common.perPage')}</option>
                    <option value={100}>100 {t('common.perPage')}</option>
                    <option value={150}>150 {t('common.perPage')}</option>
                    <option value={200}>200 {t('common.perPage')}</option>
                    <option value={250}>250 {t('common.perPage')}</option>
                </Form.Select>
                <Button variant="primary" size="sm" onClick={()=>setShowInitializerFormModal(true)}><i className="bi bi-plus"></i> {t('common.add')}</Button>
            </div>
            <VenueListView venues={currentPageVenuesData}/>

        </>
    );
}
export default VenuesListPage;