// MainLayout.tsx
import React, { useEffect, useState } from 'react';
import Header from "./header/Header";
import SideBar from "./side-bar/SideBar";
import { Outlet } from 'react-router-dom'; // For rendering child routes

const MainLayout = () => {
    const [isSideBarVisible, setIsSideBarVisible] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1199) {
                setIsSideBarVisible(true);
            } else {
                setIsSideBarVisible(false);
            }
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Header
                isSideBarVisible={isSideBarVisible}
                setIsSideBarVisible={() => setIsSideBarVisible(!isSideBarVisible)}
            />
            <SideBar isSideBarVisible={isSideBarVisible} />
            <main className={`main-content ${isSideBarVisible ? 'opened' : 'collapsed'}`} id="main">
                <Outlet /> {/* Renders nested routes here */}
            </main>
        </>
    );
};

export default MainLayout;
