import {Form, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import EventsService from "../../services/EventsService";
import {EventInterface} from "../../@interfaces/EventInterface";
import AuthenticationService from "../../services/AuthenticationService";

import EventSharedWithMe from "../../views/EventsView/EventsSharedWithMe/EventsSharedWithMe";
import MyEvents from "../../views/EventsView/MyEvents/MyEvents";
import EventsFilters from "../../components/Event/EventsFilters/EventsFilters";
import PaginationComponent from "../../components/Pagination/PaginationComponent";
import './EventsPage.scss';
import {PaginationHeader} from "../../@interfaces/PaginationHeader";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {TagInterface} from "../../@interfaces/TagInterface";
import VenuesService from "../../services/VenuesService";
import {useTranslation} from "react-i18next";

interface EventsPerPage {
    page: number;
    items: EventInterface[];
}

function EventsPage() {
     const {t, i18n} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState<EventsPerPage[]>([]);
    const [myEvents, setMyEvents] = useState<EventInterface[]>([]);
    const [venuesList, setVenuesList] = useState<VenueInterface[]>([]);
    const [tagsList, setTagsList] = useState<TagInterface[]>([]);
    const [sharedWithMeEvents, setSharedWithMeEvents] = useState<EventInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageEvents(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageEvents(pageNumber);
            } else {
                fetchEvents({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageEvents = (pageNumber: number): EventsPerPage => {

        return events.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageEvents = (pageNumber: number) => {
        try {
            const eventsPage = getCurrentPageEvents(pageNumber);
            const myEvents = eventsPage.items.filter((val: EventInterface) => val.owned);
            const sharedWithMeEvents = eventsPage.items.filter((val: EventInterface) => !val.owned);
            setMyEvents(myEvents);
            setSharedWithMeEvents(sharedWithMeEvents);

        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));

            setEvents([]);
            fetchEvents({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values:any)=>{
        try{
            fetchEvents({currentPage: 1, pageSize: itemsPerPage},values);
        } catch (e:any){
            console.error('error on line ', e);
        }
    }

    const fetchVenues =async ()=>{
        try {
            setIsLoading(true);
            const response = await VenuesService.retrieveVenues();
            setVenuesList(response.data)
            setIsLoading(false);
        } catch (e){
            setIsLoading(false);
            console.error('error on line ', e);
        }
    }

    const fetchEvents = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await EventsService.retrieveEvents({pagination: pagingData, filters: filter});
            let total_pages = Number.isNaN(Number(response.headers.total_pages)) ? 0 : Number(response.headers.total_pages);
            let page_size = Number.isNaN(Number(response.headers.page_size)) ? 0 : Number(response.headers.page_size);
            let total_count = Number.isNaN(Number(response.headers.total_count)) ? 0:Number(response.headers.total_count);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setEvents(prevData => [...prevData, {page:  Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page), items: response.data}]);
            const myEvents = response.data.filter((val: EventInterface) => val.owned);
            const sharedWithMeEvents = response.data.filter((val: EventInterface) => !val.owned);
            setMyEvents(myEvents);
            setSharedWithMeEvents(sharedWithMeEvents);
            //setCurrentPageEvents(Number(response.headers.page) ?? 0);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const login = async () => {
            try {
                await AuthenticationService.login({
                    identifier: process.env.REACT_APP_API_IDENTIFIER ?? "",
                    secret: process.env.REACT_APP_API_SECRET ?? ""
                });
            } catch (error) {
                console.error('Error logging in:', error);
            }
        };

        login();
        fetchEvents();
        fetchVenues();
    }, []);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <EventsFilters  setFiltersValue={applyFilters} venues={venuesList}/>
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div className="d-flex justify-content-between mt-4">
                        <PaginationComponent itemsPerPage={itemsPerPage} totalItems={totalCount}
                                             currentPage={currentPage}
                                             totalPages={totalPages} onPageChange={handlePageChange}/>
                        <Form.Select aria-label="Default select example" size="sm" className="custom-select"
                                     onChange={handlePageSizeChange} value={itemsPerPage}>
                            <option value={0}>N / {t('common.pages')}</option>
                            <option value={50}>50 {t('common.perPage')}</option>
                            <option value={100}>100 {t('common.perPage')}</option>
                            <option value={150}>150 {t('common.perPage')}</option>
                            <option value={200}>200 {t('common.perPage')}</option>
                            <option value={250}>250 {t('common.perPage')}</option>
                        </Form.Select>
                    </div>
                </div>

            </div>

            <Tabs defaultActiveKey="ownedby_me" className="mt-3" id="setting-tabs" fill>
                <Tab eventKey="ownedby_me" title={t('common.myEvents')} className="settings-tab-pannel">
                    <MyEvents events={myEvents}/>
                </Tab>
                <Tab eventKey="sharedwith_me" title={t('common.shareWithMe')} className="settings-tab-pannel">
                    <EventSharedWithMe events={sharedWithMeEvents}/>
                </Tab>
            </Tabs>
        </>
    );
}

export default EventsPage;
