import {ITRTicketingInterface} from "../../@interfaces/ITRTicketingInterface";
import {Button, Col, Container, Form, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ITRCredentialsModal from "../../modals/ITRCredentialsModal/ITRCredentialsModal";
import ITRBilleterieViewTranslation from "../../i18n/locales/fr/views/iTRBilleterieViewTranslation";
import {useTranslation} from "react-i18next";

const ITRsList: ITRTicketingInterface[] = [
    {name: "YurPlan", status: "active"},
    {name: "Weezevent", status: "active"},
    {name: "Ticket Master", status: "active"},
    {name: "Viva ticket", status: "active"},
    {name: "Fnac", status: "active"}
]


function ITRTicketingView() {
    const {t, i18n} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(ITRsList.map(itr => false));
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        setSelectedCheckboxes(ITRsList.map(() => false));
        setSelectedAll(false);
    }, [ITRsList]);


    //this is also used to handle all selected checkbox
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(ITRsList.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };
    return (
        <Container >

            <Row>
                <Col xs={12} sm={12} md={12}>
                    <Table>
                        <thead className="thead-light">
                        <tr>
                            <th >
                                <Form.Check
                                    type="checkbox"
                                    id={`select-all-checkbox`}
                                    checked={selectedAll}
                                    onChange={handleSelectAll}
                                />
                            </th>
                            <th >
                                {t('ITRBilleterieView.ticketingSoftware')}
                            </th>
                            <th className="text-end">{t('common.status')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {ITRsList.map((itr: ITRTicketingInterface, index) => (
                            <tr key={index}>
                                <td ><Form.Check // prettier-ignore
                                    type="checkbox"
                                    id={`checkbox-${itr.name}`}
                                    checked={selectedCheckboxes[index] || false}
                                    onChange={() => handleSelectCheckbox(index)}
                                /></td>
                                <td >{itr.name}</td>

                                <td className="text-end text-primary">{itr.status}</td>
                                <td></td>
                            </tr>))}

                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row className="py-3">
                <Col xs={12} sm={12} md={8}>
                    <Button variant="primary" className="rounded"> {t('ITRBilleterieView.findTicketingSoftware')}</Button>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Button variant="secondary"  onClick={() => setModalShow(true)} className="rounded  rounded-primary">{t('ITRBilleterieView.addITR')}</Button>
                    <Button variant="danger"  className="rounded rounded-red mx-2">{t('ITRBilleterieView.deleteITR')}</Button>
                </Col>
            </Row>

            <ITRCredentialsModal show={modalShow} onHide={() => setModalShow(false)}/>

        </Container>
    )
}

export default ITRTicketingView;