import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import AuthenticationService from "../../services/AuthenticationService";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

interface ProtectedRouteProps {
    element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const isAuth = await AuthenticationService.isAuthenticated();
                setAuthenticated(isAuth);
            } catch (error) {
                //console.error('Error checking authentication status:', error);
                setAuthenticated(false);
            }
        };

        checkAuthStatus();
    }, [authenticated]);
    if (authenticated === null) {
        return <LoadingComponent/>;  // Ou tout autre composant de chargement
    }

    return authenticated ? <>{element}</> :  <Navigate to="/auth/login" replace={true}/>;
};

export default ProtectedRoute;
