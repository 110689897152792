import {useEffect, useState} from "react";
import {User} from "oidc-client-ts";
import AuthenticationService from "../../../services/AuthenticationService";
import {useNavigate, useSearchParams} from 'react-router-dom';
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";

const Callback = () => {
    const [userInfo, setUserInfo] = useState<User | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleAuth = async () => {
        try {
            // Handle sign-in redirect callback
            const user = await AuthenticationService.handleSigninRedirectCallback();
            if (user) {
                setAuthenticated(true);
                setUserInfo(user);
            } else {
                setAuthenticated(false);
            }

        } catch (error) {
            setAuthenticated(false);
            //console.error("Authentication error:", error);
        }
    };

    const fetchUserInfo = async () => {
        try {
            const user = await AuthenticationService.getLoggedUserInfo();
            if (user) {
                setUserInfo(user);
                navigate('/events', {replace: true}); // Navigate after fetching user info
            } else {
                setAuthenticated(false);
                navigate('/auth/login', {replace: true}); // Navigate to login if no user info
            }
        } catch (error) {
            setAuthenticated(false);
            //console.error("Error fetching user info:", error);
            navigate('/auth/login', {replace: true});
        }
    }

     const handleNavigation = async () => {
            // Perform authentication check and set states

            await handleAuth();

            if (authenticated === true) {
                await fetchUserInfo();
            }
        };

    useEffect(() => {
        handleNavigation();
    }, [searchParams, authenticated, userInfo, navigate]);

    return <LoadingComponent/>;
};

export default Callback;
