import axiosClient from "./AxiosInstance";

import {createZitadelAuth, ZitadelAuth, ZitadelConfig} from "@zitadel/react";
import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';


const config: ZitadelConfig = {
    authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
    client_id:  process.env.REACT_APP_ZITADEL_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_ZITADEL_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_ZITADEL_POST_LOGOUT_REDIRECT_URI
};

const zitadelConfig = createZitadelAuth(config);

export default class AuthenticationService {

    private static zitadelConfig: ZitadelAuth;

    static configureZitadel(config: ZitadelConfig) {
        AuthenticationService.zitadelConfig = createZitadelAuth(config);
    }

    static async login(credential: { identifier: string, secret: string }) {
        try {
            const response = await axiosClient.post("/auth", credential);
            localStorage.setItem("token", response.data.jwt);
        } catch (error) {
            throw error;
        }

    }
    static async loginZitadel() {
        try {
            await zitadelConfig.authorize();
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    }

    static async isAuthenticated(): Promise<boolean> {
        try {
            if (AuthenticationService.zitadelConfig) {
                const user = await AuthenticationService.zitadelConfig.userManager.getUser();
                return !!user;
            }
            return false;
        } catch (error) {
            throw error;
        }
    }

     static async handleSigninRedirectCallback(): Promise<User | null> {
        try {
            return  await AuthenticationService.zitadelConfig.userManager.signinRedirectCallback();

        } catch (error) {
            //console.error("Error handling signin redirect callback:", error);
            throw error;
        }
    }

    static async getLoggedUserInfo(): Promise<User|null> {
        try {
            return await AuthenticationService.zitadelConfig.userManager.getUser();
        } catch (error) {
            throw error;
        }
    }

    static async logout() {
        try {
            if (AuthenticationService.zitadelConfig) {
                await AuthenticationService.zitadelConfig.signout();
            }
            localStorage.removeItem("token");
        } catch (error) {
            throw error;
        }
    }


}
AuthenticationService.configureZitadel(config);