import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

import PaginationComponent from "../../components/Pagination/PaginationComponent";
import {Button, Form} from "react-bootstrap";

import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import CompaniesService from "../../services/CompaniesService";
import CompaniesFilters from "../../components/companies/CompaniesFilters/CompaniesFilters";
import CompanyListView from "../../views/CompanyListView/CompanyListView";
import CompanyInitializerFormModal from "../../modals/CompanyInitializerFormModal/CompanyInitializerFormModal";


interface CompaniesPerPage {
    page: number;
    items: CompanyInterface[];
}
export function CompaniesListPage() {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [showInitializerFormModal, setShowInitializerFormModal] = useState(false);
    const [companies, setCompanies] = useState<CompaniesPerPage[]>([]);
    const [currentPageCompaniesData, setCurrentPageCompaniesDatas] = useState<CompanyInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageCompanies(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageCompanies(pageNumber);
            } else {
                fetchCompanies({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageCompanies = (pageNumber: number): CompaniesPerPage => {

        return companies.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageCompanies = (pageNumber: number) => {
        try {
            const companiesPage = getCurrentPageCompanies(pageNumber);
            setCurrentPageCompaniesDatas(companiesPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setCompanies([]);
            fetchCompanies({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values:any)=>{
        try{
            fetchCompanies({currentPage: 1, pageSize: itemsPerPage},values);
        } catch (e:any){
            console.error('error on line ', e);
        }
    }



    const fetchCompanies = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await CompaniesService.retrieveCompanies({pagination: pagingData, filters: filter});
            let total_pages = Number.isNaN(Number(response.headers.total_pages)) ? 0 : Number(response.headers.total_pages);
            let page_size = Number.isNaN(Number(response.headers.page_size)) ? 0 : Number(response.headers.page_size);
            let total_count = Number.isNaN(Number(response.headers.total_count)) ? 0:Number(response.headers.total_count);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setCompanies(prevData => [...prevData, {page:  Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page), items: response.data}]);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        fetchCompanies();
    },[]);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <CompaniesFilters  setFiltersValue={applyFilters}/>
            <div className="d-flex justify-content-between mt-4 mb-4">
                <PaginationComponent itemsPerPage={itemsPerPage} totalItems={totalCount} currentPage={currentPage}
                                     totalPages={totalPages} onPageChange={handlePageChange}/>
                <Form.Select aria-label="company filters select exemple" size="sm" className="custom-select"
                             onChange={handlePageSizeChange} value={itemsPerPage}>
                    <option value={0}>N / {t('common.pages')}</option>
                    <option value={50}>50 {t('common.perPage')}</option>
                    <option value={100}>100 {t('common.perPage')}</option>
                    <option value={150}>150 {t('common.perPage')}</option>
                    <option value={200}>200 {t('common.perPage')}</option>
                    <option value={250}>250 {t('common.perPage')}</option>
                </Form.Select>
                <Button variant="primary" size="sm" onClick={()=>setShowInitializerFormModal(true)}><i className="bi bi-plus"></i> {t('common.add')}</Button>
            </div>
            <CompanyListView companies={currentPageCompaniesData}/>
            <CompanyInitializerFormModal showModal={showInitializerFormModal} onHide={()=>setShowInitializerFormModal(false)}/>

        </>
    );
}
export default CompaniesListPage;