const commonTranslation = {
    pleaseSelect: "Veuillez sélectionner...",
    callSign: "Indicatif",
    email: "E-mail",
    emailPlaceholder: "E-mail",
    website: "Site web",
    vat: "TVA INTRA",
    name: "Nom",
    namePlaceHolder: "Entrer votre nom",
    firstNamePlaceHolder: "Entrer votre prénom",
    firstname: "Prénom",
    cityOfBirth: "Ville de naissance",
    countryOfBirth: "Pays de naissance",
    birthday: "Date de naissance",
    share: "Partager",
    tags: "Catégorie",
    owner: "Propriétaire",
    venue: "Lieu",
    applyFilter: "Appliquer le filtre",
    resetFilter: "Réinitialiser les filtres",
    filters: "Filtres de recherche",
    localStartDate: "Date de début",
    localEndDate: "Date de fin",
    loading: "Chargement en cours...",
    pagination: "Affichage de {startItemNumber} à {endItemNumber} sur {totalItems} éléments",
    save: "Sauvegarder",
    close: "Fermer",
    password: "Mot de passe",
    noAccount: "Vous n'avez pas encore de compte ?",
    createAccount: "Créer un compte",
    forgotPassword: "Mot de passe oublié",
    canceled: "Annuler",
    pages: "pages",
    perPage: "par page",
    adminInfo: "Informations administratives",
    imatProof: "Justificatifs d'immatriculation ou publication",
    managementInformation: "Informations sur la gérance",
    paymentMethod: "Mode de virement et paiement",
    myEvents: "Mes événements",
    shareWithMe: 'Partagés avec moi',
    title: "Titre",
    action: "Action",
    importedAt: "Importé le",
    dateShortWithSecond: "{{date, SHORT_WITH_SECONDS}}",
    status: "Statut",
    inputRequired: "Ce champ est requis !",
    invalidEmail: "Adresse e-mail invalide",
    siret: "SIRET",
    pleaseEnterPhoneNumber: "Téléphone",
    attachmentRequired: "Les justificatifs : ({{value}}) sont obligatoire.",
    legalInformation: "Renseignements juridiques",
    localOffices: "Bureaux Locaux",
    localOffice: "Bureau Local",
    addOffice: "Ajouter un bureau",
    removeOffice: "Retirer le bureau",
    invalidPhoneNumber: "Numéro de téléphone invalide",
    manager: "Dirigeant",
    addManager: "Ajouter un dirigeant",
    removeManager: "Retirer le manager",
    paymentMethods: "Mode de virement et paiement",
    invoices: "Factures",
    invoiceNumber: "N° de Facture",
    unitPrice: "Prix unitaire",
    invoiceSentDate: "Date d'émission",
    pleaseSelectPaymentMode: "Veuillez selectionner un mode de paiement",
    transfert: "Virement",
    card: "Carte",
    pleaseEnterCardNumber: "Entrer le n° de carte",
    cardNumber: "N° de carte",
    expirationDate: "Date d'expiration",
    CVV: "CVV",
    inputMinLength: "Ce champ doit contenir au moins {{value}}",
    inputMaxLength: "Ce champ doit contenir au maximum {{value}}",
    invalidExpirationDateFormat: "Le format attendu est MM/AA, MM=Mois , AA= Année",
    download: "Télécharger",
    iban: "IBAN",
    initializeCompanyAccount: "Initialisation de Compte",
    businessName: "Nom commercial",
    personContact: "Personne contact",
    pleaseEnterCompanyName: "Entrer le nom l'entreprise ou du compte",
    pleaseEnterCompanyBusinessName: "Entrer le nom commercial",
    pleaseEnterCompanyPersonContact: "Entrer le nom de personne contact",
    pleaseEnterCompanyEmail: "Entrer l'e-mail de contact",
    add: "Ajouter",
    accountType: "Type de compte",
    lastLogin: "Dernière connexion",
    company: "Compagnie",
    createdAt: "Créer le",
    updatedAt: "Mise à jour le",
    lastUpdatedAt: "Dernière mise à jour le",
    source: "Source",
    address: "Adresse",
    organisation:"Compagnie",
    roles:"Roles",
    inputURLInvalid:"URL non valide ex: https://ticketr;fr",


}
export default commonTranslation;