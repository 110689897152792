import axiosClient from "./AxiosInstance";

import {VenueInterface} from "../@interfaces/VenueInterface";
import {PaginationHeader} from "../@interfaces/PaginationHeader";

interface VenuesResponse {
    data: VenueInterface[];
    headers: any;
}

const emptyFilter = {pagination: {
    currentPage: 0,
    pageSize: 0,
    },
    filters:null
}

export default class VenuesService {
    static async retrieveVenues({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    } = emptyFilter): Promise<VenuesResponse> {
        try {
            const response = await axiosClient.post("/getvenues", {});
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }
}