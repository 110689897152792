import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {Button, Form, Table} from "react-bootstrap";


interface VenueListViewProps {
    venues: VenueInterface[];
}

function VenueListView({venues}: VenueListViewProps) {
    const {t} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(venues.map(venue => false));

    useEffect(() => {
        setSelectedCheckboxes(venues.map(() => false));
        setSelectedAll(false);
    }, [venues]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(venues.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };
    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.name')}</th>
                <th>{t('common.address')}</th>
                <th>{t('common.owner')}</th>
                <th>{t('common.createdAt')}</th>
                <th>{t('common.updatedAt')}</th>
                <th>{t('common.action')}</th>
            </tr>
            </thead>
            <tbody>

            {venues.map((venue: VenueInterface, index) => (
                <tr key={venue.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${venue.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{venue.name}</td>
                    <td>{venue.address}</td>
                    <td>TO DO</td>
                    <td>{venue.created_at}</td>
                    <td>TO DO</td>
                    <td>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-eye-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-trash"></i>
                        </Button>
                    </td>
                </tr>
            ))
            }
            </tbody>
        </Table>}
    </>)
}

export default VenueListView;