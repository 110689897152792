import {Button, Carousel, Col, Container, Form, Row, Table} from "react-bootstrap";
import {EventInterface} from "../../../@interfaces/EventInterface";
import AlertModal from "../../../modals/AlertModal/AlertModal";
import {Key, useEffect, useState} from "react";
import ShareEventModal from "../../../modals/ShareEventModal/ShareEventModal";
import {useTranslation} from "react-i18next";
import {formatDateTimeWithTimeZone} from "../../../utils/dateFormatter";

interface EventSharedWithMeViewProps {
    events: EventInterface[];
}

function EventSharedWithMe({events}: EventSharedWithMeViewProps) {
    const {t, i18n} = useTranslation();

    const [modalShow, setModalShow] = useState(false);
    const [modalShareEventShow, setModalShareEventShow] = useState(false)
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(events.map(event => false));

    useEffect(() => {
        setSelectedCheckboxes(events.map(() => false));
        setSelectedAll(false);
    }, [events]);


    //this is also used to handle all selected checkbox
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(events.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };

    return (
        <>
            {selectedCheckboxes.filter(val => val).length > 0 &&
                <Container>
                    <Row className="pt-3">
                        <Col xs={12} sm={12} md={12}>
                            <Button className="btn-sm rounded" onClick={() => setModalShareEventShow(true)}
                                    variant="primary"><i className="bi bi-share-fill"></i> {t('common.share')}</Button>
                        </Col>
                    </Row>
                </Container>
            }

            <Table striped responsive>
                <thead className="thead-light">
                <tr>
                    <th>#</th>

                    <th>
                        <Form.Check
                            type="checkbox"
                            id={`select-all-checkbox`}
                            checked={selectedAll}
                            onChange={handleSelectAll}
                        />
                    </th>
                    <th>{t('common.title')}</th>
                    <th>{t('common.venue')}</th>
                    <th>{t('common.owner')}</th>
                    <th>{t('common.source')}</th>
                    <th>{t('common.localStartDate')}</th>
                    <th>{t('common.localEndDate')}</th>
                    <th>{t('common.lastUpdatedAt')}</th>
                    <th>{t('common.action')}</th>
                </tr>
                </thead>
                <tbody>

                {events.map((event: EventInterface, index) => (
                    <tr key={event.id}>
                        <td>{index + 1}</td>
                        <td>
                            <Form.Check // prettier-ignore
                                type="checkbox"
                                id={`checkbox-${event.id}`}
                                checked={selectedCheckboxes[index] || false}
                                onChange={() => handleSelectCheckbox(index)}
                            />
                        </td>
                        <td className="d-block">
                            <Carousel indicators={false} slide={false}  style={{
                                                width: '100px', // largeur fixe
                                                height: '50px', // hauteur fixe
                                                objectFit: 'cover', // conserver le ratio d'aspect
                                            }}>
                                {event.image_urls.map((img: string | undefined, index: Key | null | undefined) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block"
                                            src={img}
                                            alt={`Slide ${index}`}
                                            style={{
                                                width: '100px', // largeur fixe
                                                height: '50px', // hauteur fixe
                                                objectFit: 'cover', // conserver le ratio d'aspect
                                            }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            {event.name}

                        </td>

                        <td>TO DO</td>
                        <th>TO DO</th>
                        <th>TO DO</th>
                        <td>{formatDateTimeWithTimeZone(event.local_start_date, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                        <td>{formatDateTimeWithTimeZone(event.local_end_date, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                        <td>{formatDateTimeWithTimeZone(event.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                        <td>
                            <Button variant="outline-primary" size="sm" onClick={() => setModalShow(true)}>
                                <i className="bi bi-eye-fill"></i>
                            </Button>
                        </td>
                    </tr>
                ))
                }
                </tbody>
            </Table>
            <AlertModal show={modalShow} onHide={() => setModalShow(false)} title={"Details d'évènement"}
                        body={"An automated Boy wil be there "}/>
            <ShareEventModal show={modalShareEventShow} onHide={() => setModalShareEventShow(false)}/>
        </>
    )
}

export default EventSharedWithMe