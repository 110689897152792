import "./SideBar.scss";
import routes from "../../router/routes";

import React from "react";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface SideBarProps {
    isSideBarVisible?: boolean;
}

function SideBar({isSideBarVisible}: SideBarProps) {
    const menus = routes.filter((item: any) => item.showOnSideBar === true);
    const { t } = useTranslation();

    return (
        <aside id="sidebar" className={`sidebar ${isSideBarVisible ? 'opened' : 'collapsed'}`}>
            <ul className="sidebar-nav" id="sidebar-nav">
                {menus.map((item: any, index: number) => (
                    <li className="nav-item" key={index}>
                        <NavLink
                            className={({isActive}) =>
                                `nav-link collapsed ${isActive ? 'active' : ''} ${isSideBarVisible ? '' : 'side-closed'}`
                            }
                            to={item.path}
                        >
                            <i className={item.icon}></i>
                            {isSideBarVisible && <span>{t(item.label)}</span>}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </aside>
    );
}

export default SideBar;
