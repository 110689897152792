import AccountPage from "../pages/AccountPage/AccountPage";
import EventsPage from "../pages/EventsPage/EventsPage";
import i18next from 'i18next';
import "../i18n/i18n"
import CompaniesListPage from "../pages/CompaniesListPage/CompaniesListPage";
import LoginPage from "../pages/AuthenticationPages/LoginPage/LoginPage";
import Callback from "../pages/AuthenticationPages/LoginPage/Callback";
import VenueListPage from "../pages/VenueListPage/VenueListPage";
import AccountListPage from "../pages/AccountListPage/AccountListPage";

export interface Route {
    path: string;
    element?: React.ReactNode;
    showOnSideBar: boolean;
    children?: Route[];
    label: string;
    icon?: string;
    protected: boolean;
}

const routes: Route[] = [
    {
        path: "/",
        element: (
            <div>
                <p>{i18next.t('sideBar.dashboard')}</p>
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.dashboard',
        icon: "bi bi-grid",
        protected: true
    },
    {
        path: "/messenger",
        element: (
            <div>
                {i18next.t('sideBar.messenger')}
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.messenger',
        icon: "bi bi-messenger",
        protected: true
    },
    {
        path: "/events",
        element: <EventsPage/>,
        icon: "bi bi-ticket-fill",
        showOnSideBar: true,
        label: 'sideBar.events',
        protected: true
    },
    {
        path: "/chanel-manager",
        element: (
            <div>
                {i18next.t('sideBar.chanelManager')}
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.chanelManager',
        icon: "bi bi-grid-fill",
        protected: true
    },
    {
        path: "/statistique",
        element: (
            <div>
                {i18next.t('sideBar.statistics')}
            </div>
        ),
        showOnSideBar: false,
        label: 'sideBar.statistics',
        icon: "bi bi-file-bar-graph-fill",
        protected: true
    },
    {
        path: "/account",
        element: <AccountPage/>,
        icon: "bi bi-ticket-fill",
        showOnSideBar: false,
        label: 'sideBar.account',
        protected: true
    },
    {
        path: "/companies",
        element: <CompaniesListPage/>,
        icon: "bi bi-briefcase-fill",
        showOnSideBar: true,
        label: 'sideBar.companies',
        protected: true
    },
    {
        path: "/venues",
        element: <VenueListPage/>,  // Corrected from string to JSX
        icon: "bi bi-buildings-fill",  // Fixed the icon reference
        showOnSideBar: true,
        label: 'sideBar.venues',
        protected: true
    },
    {
        path: "/accounts",
        element: <AccountListPage/>,  // Corrected from string to JSX
        icon: "bi bi-file-person-fill",
        showOnSideBar: true,
        label: 'sideBar.users',
        protected: true
    },

];

export default routes;
