const companyFormTranslation = {
    companyName: 'Nom de l\'entreprise',
    companyHeadOfficeCountry:'Pays du siège social',
    companyAddress: 'Adresse',
    companyPostalCode: 'Code Postal',
    companyCity:'Ville',
    companyDistrict:'Région',
    localOfficeAddress: "Adresse postale du bureau local",
    proofsDownloadable:"Télécharger vos justificatifs ({{value}})",
    uploadRequired: "Ce champ est obligatoire",
    entertainmentLicenceReceiptNumber:"Numéro de récipissé de licence d'entrepreneur de spectacle",
    entertainmentReceiptNumberInvalid:"Ce champ doit contenir 40 caractères",
    businessName:"Nom commercial",
    businessSector:"Secteur d'activités",
    atoutFranceRegistrationNumber:"Immatriculation au registre Atout France",
    licenceCategory:"Nature catégorie de licence (Type d’activité)",
    licenseCategory:"Catégorie {{value}}"


}
export default companyFormTranslation