import {Button, Modal, Table} from "react-bootstrap";
import Select, {InputActionMeta, MultiValue} from 'react-select'
import {useState} from "react";
import {useTranslation} from "react-i18next";

const options = [
    {value: 'Compagnie 1', label: 'Compagnie 1'},
    {value: 'Compagnie 2', label: 'Compagnie 2'},
    {value: 'Compagnie N', label: 'Compaie N'}
]

function ShareEventModal(props: any) {
     const {t, i18n} = useTranslation();
    const [companies, setCompanies] = useState(options);
    const [selectedCompanies, setSelectedCompanies] = useState<MultiValue<{
        value: string;
        label: string;
    }> | null>(null);

    const onInputChange = (
        inputValue: string,
        {action, prevInputValue}: InputActionMeta
    ) => {
        return prevInputValue;
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('ShareEventModal.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{t('ShareEventModal.pleaseSelectCompany')} </h5>
                <Select
                    closeMenuOnSelect={false}
                    onChange={setSelectedCompanies}
                    isMulti
                    options={companies}
                    className="mb-3 mt-3"
                />
                {selectedCompanies && selectedCompanies.length > 0 && (
                    <>
                        <p>{t('ShareEventModal.description')}</p>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>{t('common.name')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedCompanies.map((value, index) => (
                                <tr key={index}>
                                    <td>{value.label}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                )}


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} className="rounded">{t('common.share')}</Button>
                <Button onClick={props.onHide} className="rounded" variant="danger">{t('common.canceled')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ShareEventModal;