import {Col, Container, Form, Row} from "react-bootstrap";
import UploadComponent from "../../components/upload/UploadComponent";

import CompanyForm from "../../components/companies/CompanyForm/CompanyForm";
import CompanyManagementForm from "../../components/companies/CompanyManagementForm/CompanyManagementForm";
import './CompanyView.scss'
import {useTranslation} from "react-i18next";
function CompanyView() {
    const {t, i18n} = useTranslation();
    return (
        <Container fluid className="py-5 px-5">

                <Row className="mb-3">
                    <Col lg={12} md={12} sm={12}>
                        <h4 >{t('common.adminInfo')}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12}>
                        <CompanyForm/>
                    </Col>


                </Row>

            {/*<Row>
                <Col lg={12} md={12} sm={12} className="mt-3">
                    <h4>{t('common.managementInformation')}</h4>
                </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12} className="mt-3">
                        <CompanyManagementForm/>
                    </Col>
                </Row>*/}

        </Container>
    )
}

export default CompanyView;