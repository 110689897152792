// AuthRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/AuthenticationPages/LoginPage/LoginPage';
import Callback from '../pages/AuthenticationPages/LoginPage/Callback';

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="login" element={<LoginPage />} />
            <Route path="callback" element={<Callback />} />
        </Routes>
    );
};

export default AuthRoutes;
