import axiosClient from "./AxiosInstance";
import {EventInterface} from "../@interfaces/EventInterface";

import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";

// Define the expected response structure
interface EventsResponse {
    data: EventInterface[];
    headers: any;
}


export default class EventsService {
    static async retrieveEvents({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    }): Promise<EventsResponse> {
        try {
            const formattedFilters = EventsService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/events?${!isEmpty(formattedFilters) ? formattedFilters : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static async retrieveEvent(uuid: number) {
        try {
            const response = await axiosClient.get(`/events/${uuid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async shareEvent(payload: { recipient_id: string, target_type: string, target_ids: string[] }) {
        try {
            const response = await axiosClient.post(`/getevents/share`, payload);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static formatFiltersToQueryString(filters: any) {
        if (!isEmpty(filters)) {
            const filter = {
                venues_ids: filters.venues.map((val: any) => val.value),
                start_date: filters.localStartDate,
                end_date: filters.localEndDate
            }
            // Remove all undefined keys
            const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null && value !== '' && value.length != 0)
            );
            return new URLSearchParams(Object.entries(filteredFilter)).toString();
        }

        return filters;
    }
}