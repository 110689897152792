import {Link, Navigate} from "react-router-dom";
import { useEffect, useState } from "react";
import AuthenticationService from "../../../services/AuthenticationService";
import './LoginPage.scss'; // Assurez-vous d'ajouter ce fichier CSS dans le même répertoire
// @ts-ignore
import logo from '../../../assets/logo-ticketr.svg'
import {Button} from "react-bootstrap";

function LoginPage(): JSX.Element {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const isAuth = await AuthenticationService.isAuthenticated();
                setAuthenticated(isAuth);
            } catch (error) {
                console.error("Error checking authentication:", error);
                setAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    const handleLogin = async () => {
        try {
            await AuthenticationService.loginZitadel();
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    if (authenticated) {
        return <Navigate to="/events" replace={true} />;
    }

    return (
        <div className="login-page">
            <div className="login-left">

            </div>
            <div className="login-right">
                <div >
                    <img src={logo} alt="Logo" className="login-logo mb-5" height={100}/>
                    <h4>Rejoignez la communauté ticketr et bénéficiez de nombreux canaux de vente supplémentaire et le tout gratuitement</h4>
                    <br/>
                    <Button onClick={handleLogin} className="mt-3" variant="primary" size="lg">
                        Se connecter
                    </Button>
                    <p className="mt-5"><i>Vous n'avez pas de compte ? Pas de problème ! <Link to="#" onClick={handleLogin}> Créer en un ici gratuitement </Link></i></p>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
